import { create } from 'zustand';

import { ReportState } from './useReport.type';

export const useReport = create<ReportState>((set) => ({
  isPreviewMode: false,
  report: null,
  setPreviewMode: (mode) => set({ isPreviewMode: mode }),
  setReport: (report) => set({ report }),
}));
