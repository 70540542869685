import * as React from 'react';

import { cn } from '@/lib/utils';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => (
    <textarea
      className={cn(
        'flex min-h-[80px] w-full resize-none rounded-md border border-input bg-stone-950 dark:bg-stone-950 px-6 py-3 text-sm ring-offset-background placeholder:text-neutral-400 dark:placeholder:text-neutral-400 disabled:cursor-not-allowed disabled:opacity-50 focus:ring-transparent',
        className,
      )}
      ref={ref}
      {...props}
    />
  ),
);

Textarea.displayName = 'Textarea';

export { Textarea };
