import { cva } from 'class-variance-authority';

export const DraggableKeyImages = cva('size-[128px] cursor-pointer rounded-md object-cover', {
  variants: {
    state: {
      selected: 'border-2 border-blue-500 dark:border-blue-500',
      unselected: 'border-transparent',
    },
  },
});
