import { cva } from 'class-variance-authority';

export const DotIndicatoVariants = {
  container: 'flex items-center cursor-pointer',
  dot: cva('mr-1 size-4 rounded-full border', {
    defaultVariants: {
      filled: false,
      hovered: false,
    },
    variants: {
      filled: {
        false: 'border-neutral-800',
        true: 'border-blue-500 bg-blue-500',
      },
      hovered: {
        false: '',
        true: 'border-neutral-800 bg-neutral-900',
      },
    },
  }),
  label: 'mr-2 text-neutral-600 uppercase',
};
