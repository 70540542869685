import { cva } from 'class-variance-authority';

export const organContainerVariants = cva('mt-8 px-[162px]', {
  variants: {
    last: {
      false: 'border-b-4 border-neutral-800',
      true: '',
    },
  },
});
