import { cn } from '@prenuvo/halo-web';

import { DotIndicator } from '@/components/DotIndicator/DotIndicator';
import { DraggableKeyImages } from '@/components/DraggableKeyImages/DraggableKeyImages';
import { Editor } from '@/components/Editor';
import { useKeyImages } from '@/store/useKeyImages/useKeyImages';
import { replaceConditionWithValue, transformSentenceToKebabCase } from '@/utils/utils';

import { splitFindingDetails } from '../../utils';
import { findingsContainerVariants } from './Finding.cva';
import { FindingProps } from './Finding.type';

export function Finding({ finding, isLast, isOnDemandCondition = false }: FindingProps) {
  const formattedOrganTitle = transformSentenceToKebabCase(finding.organ);
  const { localKeyImages } = useKeyImages();
  const { actionDetails, actionSummary, findingDetails, findingSummary } = finding;
  const isEditorReadOnly = true;

  if (!actionSummary && !actionDetails && !findingDetails && !findingSummary) {
    return null;
  }

  return (
    <div>
      <div
        className={cn({ 'mt-12 border-b border-stone-700 pb-6': !isOnDemandCondition })}
        data-testid={`${formattedOrganTitle}-finding`}
      >
        {findingSummary && (
          <div className="text-lg font-bold" data-testid="finding-summary">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(findingSummary)}
            />
          </div>
        )}
        {/* <p className="mb-8 mt-2 text-sm font-semibold text-red-400 dark:text-red-400">
          High Significance Finding (HardCoded)
        </p> */}
        {findingDetails && (
          <div>
            <ul className="list-disc p-5">
              {splitFindingDetails(findingDetails).map((detail) => (
                <li key={detail} className="text-neutral-50" data-testid="finding-detail">
                  {detail}
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* <div className="my-8">
          <p className="text-sm font-bold text-neutral-400">See figure 1 HardCoded</p>
        </div> */}
        <div
          className={cn('rounded-xl bg-neutral-950 p-4', {
            hidden: !actionSummary && !actionDetails,
          })}
        >
          {actionSummary && (
            <div className="mb-3 text-lg font-extrabold" data-testid="action-summary">
              <Editor
                isReadOnly={isEditorReadOnly}
                value={replaceConditionWithValue(actionSummary)}
              />
            </div>
          )}
          {actionDetails && (
            <div data-testid="action-detail">
              <Editor
                isReadOnly={isEditorReadOnly}
                value={replaceConditionWithValue(actionDetails)}
              />
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="key-image-scrollbar flex w-9/12 overflow-x-auto pb-2">
          <div className="flex size-full gap-2" data-testid="finding-key-images">
            {finding.uuid &&
              (!isOnDemandCondition || isLast) &&
              localKeyImages[isOnDemandCondition ? `${finding.organ}-ondemand` : finding.uuid]?.map(
                (KeyImage) => (
                  <DraggableKeyImages
                    id={KeyImage.id}
                    key={KeyImage.id}
                    isViewMode
                    keyImage={KeyImage}
                    selected={false}
                    shouldEnableHover={false}
                  />
                ),
              )}
          </div>
        </div>
        {!isOnDemandCondition && (
          <div
            className={cn(findingsContainerVariants({ bordered: !isLast }))}
            data-testid="finding-dot-indicators"
          >
            <DotIndicator filledDots={finding.oncoradsScore} isViewMode label="ONCO" />
            <DotIndicator filledDots={finding.csdScore} isViewMode label="CSD" />
          </div>
        )}
      </div>
    </div>
  );
}
