import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import { App } from './App';
import { logger } from './logger';

logger.debug('Starting application');

async function enableMocking() {
  if (import.meta.env.VITE_ENABLE_MSW !== 'localhost') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  worker.start();
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
