import { useEffect } from 'react';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest/makeApiRequest';
import { useHiStudy } from '@/store';
import { ScanDetails } from '@/store/useHiStudy/useHiStudy.type';

export const useScanDetailsQuery = (studyId?: string) => {
  const { setIsScanDetailLoading, setScanDetails } = useHiStudy();

  const fetchScanDetails = async () => {
    setIsScanDetailLoading(true);

    try {
      const { data } = await makeApiRequest<ScanDetails>(
        'APPS',
        `${API_ENDPOINTS.hi.study}/${studyId}/radiologistScanDetails`,
        'GET',
      );

      setScanDetails(data);
      setIsScanDetailLoading(false);
    } catch (errorResponse: Error | unknown) {
      setIsScanDetailLoading(false);
      throw new Error(
        `Issue getting radiologist scan details, ${(errorResponse as Error).message}`,
      );
    }
  };

  useEffect(() => {
    if (studyId) {
      fetchScanDetails();
    }
  }, [studyId]);
};
