import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Typography } from '@prenuvo/halo-web';

import { SignatureProps } from './Signature.types';

export const Signature = forwardRef(
  ({ className, description, onFinishSigning, signatureData, title }: SignatureProps, ref) => {
    const signatureCanvasRef = useRef<null | SignatureCanvas>(null);

    useEffect(() => {
      if (signatureData && signatureCanvasRef.current) {
        signatureCanvasRef.current.fromDataURL(`data:image/png;base64,${signatureData}`);
        signatureCanvasRef.current.off();
      }
    }, [signatureData, signatureCanvasRef]);

    const clearSignature = () => {
      signatureCanvasRef.current?.clear();
      signatureCanvasRef.current?.on();
      onFinishSigning(false);
    };

    const getSignature = () => {
      const signature = signatureCanvasRef.current?.toDataURL();

      return signature;
    };

    useImperativeHandle(ref, () => ({
      getSignature,
    }));

    return (
      <div className={className}>
        {title && <Typography variant="h5">{title}:</Typography>}
        {description && (
          <Typography className="text-neutral-400 dark:text-neutral-400" variant="paragraph-sm">
            {description}:
          </Typography>
        )}
        <div className="my-6 h-[164px]">
          <SignatureCanvas
            canvasProps={{
              className: 'w-full h-full bg-neutral-50 dark:bg-neutral-50 rounded-2xl',
            }}
            data-testid="signature-canvas"
            onEnd={() => onFinishSigning(true)}
            penColor="black"
            ref={signatureCanvasRef}
          />
          <Button
            className={{ root: 'ml-auto mr-4 mt-2 px-4' }}
            data-testid="clear-signature-button"
            onClick={clearSignature}
            size="sm"
            variant="outlined"
          >
            Clear Signature
          </Button>
        </div>
      </div>
    );
  },
);

Signature.displayName = 'Signature';
