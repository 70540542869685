import { config } from '../../config';
import { ApiId } from './types';

export const getApiUri = (apiId: ApiId): string => {
  const hosts = {
    APPS: config.APPS_SERVER,
    FCT: config.FCT_SERVER,
    HI: config.HI_SERVER,
    PII: config.PII_SERVER,
  };

  return hosts[apiId];
};
