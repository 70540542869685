import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Report } from '@/components/Report/Report.type';
import { API_ENDPOINTS } from '@/core/api/endpoints';
import { HttpMethod } from '@/core/constants';
import { useFindingsStore } from '@/store/useFindings/useFindingsStore';
import { useReport } from '@/store/useReport/useReport';
import { RenderedSummary } from '@/types/structuredFinding';
import { groupFindingsByOrgan } from '@/utils/utils';

import { useApiRequest } from '../useApiRequest/useApiRequest';

export const useFetchReport = () => {
  const { setOrgans } = useFindingsStore();
  const { handleApiRequest } = useApiRequest();
  const { report, setReport } = useReport();

  const { id: studyId } = useParams<{ id: string }>();

  const getReport = async () => {
    if (!report) {
      await handleApiRequest<Report>(
        `${API_ENDPOINTS.report.getReport}/${studyId}?user_id=auth0|5ffe1191e942a2006fae2bac`,
        HttpMethod.GET,
        {},
        async (reportObj) => {
          setReport(reportObj);
          await handleApiRequest<RenderedSummary[]>(
            `${API_ENDPOINTS.finding.renderedSummary}report/${reportObj.uuid}`,
            HttpMethod.GET,
            {},
            (renderedSummary) => {
              const groupedData = groupFindingsByOrgan(renderedSummary);

              setOrgans(groupedData);
            },
          );
        },
      );
    }
  };

  useEffect(() => {
    getReport();
  }, []);
};
