import { transformSentenceToKebabCase } from '@/utils/utils';

import { Organ } from '../Organ/Organ';
import { SectionProps } from './Section.type';

export function Section({ sectionData }: SectionProps) {
  const formattedSectionName = transformSentenceToKebabCase(sectionData.sectionName);

  return (
    <div className="mt-8 h-full" data-testid={`${formattedSectionName}-section`}>
      <div className="h-16 border-y border-stone-700 bg-stone-950">
        <p
          className="px-[162px] py-[18.5px] text-xl font-bold text-neutral-200"
          data-testid={`${formattedSectionName}-section-title`}
        >
          {sectionData.sectionName}
        </p>
      </div>
      {sectionData.organs.map((organ, organIndex) => (
        <Organ
          key={organ.title}
          isLast={organIndex === sectionData.organs.length - 1}
          organ={organ}
        />
      ))}
    </div>
  );
}
