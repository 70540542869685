import { v4 } from 'uuid';

export const ICONS = {
  check: 'check',
  delete: 'delete',
  edit: 'edit',
};

export const SELECTED = 'selected';
export const UNSELECTED = 'unselected';
export const EDIT = 'edit';
export const DELETE = 'delete';

export const OPTIONS = [
  {
    id: v4(),
    icon: ICONS.edit,
    value: EDIT,
  },
  {
    id: v4(),
    icon: ICONS.delete,
    value: DELETE,
  },
];
