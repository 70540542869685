import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlignLeft } from '@prenuvo/halo-icon';
import { Icon, Typography } from '@prenuvo/halo-web';

import { Editor } from '@/components/Editor';
import { useSaveTechniqueNotes } from '@/hooks/mutations/useSaveTechniqueNotes/useSaveTechniqueNotes';
import { useOutsideClick } from '@/hooks/useOutsideClick/useOutsideClick';
import { useHiStudy } from '@/store';
import { usePatient } from '@/store/usePatient/usePatient';
import { useReport } from '@/store/useReport/useReport';
import { formatEditorString } from '@/utils/utils';

export function Technique({ isReadOnly = false }: { isReadOnly?: boolean }) {
  const { scanDetails } = useHiStudy();
  const { patient } = usePatient();
  const { report, setReport } = useReport();

  const [techniqueNotes, setTechniqueNotes] = useState('');
  const addNotesRef = useRef<HTMLDivElement>(null);
  const addNotesEditorRef = useRef<{ blur: () => void } | null>(null);
  const { id: studyId } = useParams<{ id: string }>();
  const mutation = useSaveTechniqueNotes(studyId || '');

  const saveTechnique = async (updatedNote: string = '') => {
    const newNote = formatEditorString(updatedNote);
    const CurrentNote = formatEditorString(techniqueNotes);

    if (newNote !== CurrentNote) {
      try {
        const response = await mutation.mutateAsync({ techniqueNotes: updatedNote });

        if (response && report) {
          const { techniqueNotes: updatedTechniqueNotes } = response as { techniqueNotes: string };
          const updatedReport = { ...report, techniqueNotes: updatedTechniqueNotes };

          setReport(updatedReport);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving technique notes:', error);
      }

      if (mutation.isSuccess) {
        setTechniqueNotes(updatedNote);
      }
    }
  };

  useEffect(() => {
    if (report) {
      const notesFromReport = report?.techniqueNotes;

      setTechniqueNotes(notesFromReport);
    }
  }, [report]);

  useOutsideClick([addNotesRef], () => {
    if (addNotesEditorRef.current) {
      addNotesEditorRef.current.blur();
    }
  });

  return (
    <div className="space-y-4">
      <Typography as="div" data-testid="technique-title" variant="paragraph-lg-bold">
        {isReadOnly ? 'TECHNIQUE' : 'Technique'}
      </Typography>
      <div className="space-y-4">
        <div
          className={` ${!isReadOnly && 'rounded-md border border-neutral-800 p-4'}`}
          data-testid="technique-data"
        >
          {patient && scanDetails.technique && patient.gender ? (
            <div className="flex gap-2">
              {!isReadOnly && <Icon className="fill-neutral-400" size="lg" source={AlignLeft} />}
              <Typography data-testid="technique-patient-result">
                {scanDetails.technique[patient.gender]}
              </Typography>
            </div>
          ) : (
            <Typography>Not provided</Typography>
          )}
        </div>
        <div
          className={` ${!isReadOnly && 'rounded-md border border-neutral-800 p-4'}`}
          data-testid="add-notes-to-technique"
        >
          <div className="flex gap-2">
            {!isReadOnly && <Icon className="fill-neutral-400" size="md" source={AlignLeft} />}
            <div className="max-h-40 w-full overflow-y-auto overflow-x-hidden">
              <Typography as="div" variant="paragraph-sm">
                <span className="text-neutral-400">
                  {isReadOnly ? 'Additional Note' : 'Add notes to technique'}
                </span>
              </Typography>
              <div ref={addNotesRef}>
                <Typography as="div" variant="paragraph-md">
                  <Editor
                    isReadOnly={isReadOnly}
                    onBlur={(_, current) => saveTechnique(current)}
                    placeholder="Type your notes here"
                    ref={addNotesEditorRef}
                    value={techniqueNotes}
                  />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
