import { Cross, Edit } from '@prenuvo/halo-icon';
import { Icon } from '@prenuvo/halo-web';
import { AnimatePresence, motion } from 'framer-motion';

import { OPTIONS } from '../../DraggableKeyImages.constants';

function ImageOptions({
  handleActions,
  hovered,
  isViewMode,
}: {
  handleActions: (action: string) => () => void;
  hovered: boolean;
  isViewMode: boolean;
}) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>, value: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleActions(value);
    }
  };

  return (
    <AnimatePresence>
      {hovered && isViewMode && (
        <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
          <div className="pointer-events-none absolute z-10 size-full rounded-md bg-black opacity-80" />
          <div className="absolute right-1 top-1 z-20 flex gap-2">
            {OPTIONS.map((option) => (
              <div
                key={option.id}
                className="flex size-8 cursor-pointer items-center justify-center rounded-full border-2 border-stone-700 transition-all duration-200 ease-in-out hover:border-blue-500 dark:border-stone-700 dark:hover:border-blue-500"
                onClick={handleActions(option.value)}
                onKeyDown={(event) => handleKeyDown(event, option.value)}
                role="button"
                tabIndex={0}
              >
                <span key={option.id} data-testid="image-option">
                  <Icon
                    aria-label={option.value}
                    className="fill-neutral-50"
                    size="xs"
                    source={option.icon === 'edit' ? Edit : Cross}
                  />
                </span>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export { ImageOptions };
