import { ReactNode, useMemo, useState } from 'react';

import { LoadingScreen } from '../../components';
import { useAuthStore } from '../../core/store/auth/authStore';
import { useCurrentUserQuery } from '../../hooks/queries/useCurrentUserQuery/useCurrentUserQuery';
import { AuthContext } from './AuthContext';

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const { isAuthenticated } = useAuthStore();
  const { user, userLoading } = useCurrentUserQuery();
  const [userSignature, setUserSignature] = useState('');

  const providerProps = useMemo(
    () => ({
      isAuthenticated,
      setUserSignature,
      user,
      userSignature,
    }),
    [isAuthenticated, user, userSignature],
  );

  if (userLoading) {
    return <LoadingScreen />;
  }

  return <AuthContext.Provider value={providerProps}>{children}</AuthContext.Provider>;
}
