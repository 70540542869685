export const extractValues = (inputString: string): string => {
  const pattern = /<{name: ".*?", value: "(.*?)"(, uuid: ".*?")?}>/g;
  const outputString = inputString.replace(pattern, (_, value) => value);

  return outputString;
};

export const splitFindingDetails = (details: string): string[] =>
  details
    .split(/\n/)
    .filter((detail) => detail.trim() !== '')
    .map((detail) => extractValues(detail));
