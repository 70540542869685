export enum Progress {
  IN_PROGRESS = 'InProgress',
  ERROR = 'Error',
  COMPLETED = 'Completed',
}

export type NavListProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
  key: string;
  progress: null | Progress;
  title: string;
};

export type NavMenuProps = {
  isCollapsed: boolean;
  isReviewVisible?: boolean;
  links: NavListProps[];
  maxSize: string;
  minSize: string;
  onLinkSelect?: (link: string) => void;
  onReviewClick?: () => void;
  onToggleSideMenu: () => void;
  selectedLinkValue?: string;
};

export type ProgressIndicatorProps = {
  isCollapsed: boolean;
  progress: null | Progress;
};
