import { create } from 'zustand';

import { OnDemanConditionState, OnDemandConditionsData } from './useOnDemandConditionsStore.types';

export const useOnDemandConditionsStore = create<OnDemanConditionState>((set, get) => ({
  getOnDemandConditions: (organ: string) => get().onDemandConditons[organ] || [],
  onDemandConditons: {},
  setOnDemandConditons: (organ: string, onDemandConditions: OnDemandConditionsData[]) => {
    set((state) => ({
      onDemandConditons: {
        ...state.onDemandConditons,
        [organ]: [...(state.onDemandConditons[organ] ?? []), ...onDemandConditions],
      },
    }));
  },
  updateOnDemandCondition: (
    organ: string,
    condition: string,
    onDemandCondition: OnDemandConditionsData,
  ) => {
    set((state) => {
      const organConditions = state.onDemandConditons[organ] || [];
      const index = organConditions.findIndex((c) => c.condition === condition);

      if (index > -1) {
        organConditions[index] = onDemandCondition;
      }

      return {
        onDemandConditons: {
          ...state.onDemandConditons,
          [organ]: organConditions,
        },
      };
    });
  },
}));
