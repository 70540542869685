import { cva } from 'class-variance-authority';

export const findingsContainerVariants = cva('mt-9 flex items-center gap-4 pb-9', {
  variants: {
    bordered: {
      false: '',
      true: 'border-b border-stone-200',
    },
  },
});
