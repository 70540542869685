import { FunctionComponent, lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { ReportingLayout } from '@/Layouts';
import { Report } from '@/pages/Report/Report';

import { LoadingScreen } from '../components';
import { config } from '../config';
import { AuthGuard } from '../guards/authGuard/AuthGuard';

const PATH_AFTER_LOGIN = `${config.BASE_NAME}/99999999-eeee-4444-aaaa-222222222222/entry`;

const Loadable = (Component: FunctionComponent<NonNullable<unknown>>) =>
  function LoadableComponent(props: NonNullable<unknown>) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };

const FindingCapture = Loadable(
  lazy(async () => ({
    default: (await import('../pages/FindingCapture/FindingCapture')).LandingPage,
  })),
);

const NotFound = Loadable(
  lazy(async () => ({
    default: (await import('../pages/NotFound')).NotFoundPage,
  })),
);

const NoAccess = Loadable(
  lazy(async () => ({
    default: (await import('../pages/NoAccess')).NoAccessPage,
  })),
);

function Router() {
  return useRoutes([
    {
      children: [
        { element: <Navigate replace to={PATH_AFTER_LOGIN} />, index: true },
        { element: <FindingCapture />, path: ':id/entry' },
        { element: <Report />, path: ':id/preview' },
      ],
      element: (
        <AuthGuard>
          <ReportingLayout />
        </AuthGuard>
      ),
      path: '/reporting',
    },

    { element: <Navigate replace to={PATH_AFTER_LOGIN} />, index: true, path: '/' },

    {
      children: [
        { element: <NotFound />, path: '404' },
        { element: <NoAccess />, path: '401' },
        { element: <Navigate replace to="/404" />, path: '*' },
      ],
      path: '*',
    },
    { element: <Navigate replace to="/404" />, path: '*' },
  ]);
}

export { Router as AppRouter };
