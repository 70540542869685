import { Outlet } from 'react-router-dom';

import { Header } from '@/components';

export function ReportingLayout() {
  return (
    <div className="flex h-screen flex-col">
      <Header />
      <Outlet />
    </div>
  );
}
