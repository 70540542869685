import { RefObject, useEffect } from 'react';

type OutsideClickHandler = () => void;

export const useOutsideClick = (
  refs: RefObject<HTMLElement>[],
  onOutsideClick: OutsideClickHandler,
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, onOutsideClick]);
};
