import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft, Document } from '@prenuvo/halo-icon';
import { Button, IconButton, Tooltip } from '@prenuvo/halo-web';

import { config } from '@/config';
import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest/makeApiRequest';
import { useScanDetailsQuery } from '@/hooks/queries/useScanDetails/useScanDetailsQuery';
import { cn } from '@/lib/utils';
import { useHiStudy } from '@/store';
import { usePatient } from '@/store/usePatient/usePatient';
import { useReport } from '@/store/useReport/useReport';
import { StudiesResponse } from '@/types/study';
import { User } from '@/types/user';
import { calculateAge, formatTimestamp } from '@/utils/utils';

import { Logo } from '../../assets/icons';
import { GenderSelect } from './GenderSelect';
import { HeaderProps } from './Header.types';

export function Header({ className }: HeaderProps) {
  const { id: studyId } = useParams();
  const [patient, setPatient] = useState<null | User>(null);
  const [previousStudyIds, setPreviousStudyIds] = useState<string[]>([]);
  const [gender, setGender] = useState<string>('');
  const { scanDetails } = useHiStudy();
  const { isPreviewMode, setPreviewMode } = useReport();
  const hasPatientDetailsFetched = useRef(false);
  const hasPatientStudiesFetched = useRef(false);
  const navigate = useNavigate();
  const { setPatient: setPatientData } = usePatient();

  useScanDetailsQuery(studyId);
  const { machineID, patientID, scanDate, skuID } = scanDetails;

  const navigateBack = () => {
    navigate(-1);
    setPreviewMode(false);
  };

  useEffect(() => {
    const fetchPatientDetails = async (userID: string) => {
      try {
        const { data } = await makeApiRequest<User[]>(
          'PII',
          `${API_ENDPOINTS.user.getPatient}?user_id=${userID}`,
          'GET',
        );

        hasPatientDetailsFetched.current = true;

        if (data.length) {
          setPatient(data[0]);

          setPatientData(data[0]);

          if (data[0].gender) {
            setGender(data[0].gender);
          }
        } else {
          throw new Error('No patient details found');
        }
      } catch (errorResponse: Error | unknown) {
        throw new Error(`Issue getting patient details, ${(errorResponse as Error).message}`);
      }
    };

    if (patientID && !hasPatientDetailsFetched.current) {
      fetchPatientDetails(patientID);
    }
  }, [patientID, hasPatientDetailsFetched]);

  useEffect(() => {
    const fetchPatientStudies = async (userID: string) => {
      try {
        const { data } = await makeApiRequest<StudiesResponse>(
          'APPS',
          `${API_ENDPOINTS.study.getStudy}?users=${userID}&status=scanned`,
          'GET',
        );

        hasPatientStudiesFetched.current = true;

        if (data?.studies?.length > 0) {
          const previousStudies = data?.studies?.filter(
            (study) => study.studyID !== studyId && study.booking[0].utcStart < scanDate,
          );

          if (previousStudies?.length > 0) {
            const sortedStudies = previousStudies
              .sort((a, b) => b.booking[0].utcStart - a.booking[0].utcStart)
              .slice(0, 8);

            setPreviousStudyIds(sortedStudies.map((study) => study.studyID));
          } else {
            throw new Error('No previous studies found');
          }
        } else {
          throw new Error('No patient studies found');
        }
      } catch (errorResponse: Error | unknown) {
        throw new Error(`Issue getting patient studies, ${(errorResponse as Error).message}`);
      }
    };

    if (patientID && !hasPatientStudiesFetched.current) {
      fetchPatientStudies(patientID);
    }
  }, [patientID, hasPatientStudiesFetched]);
  const infoItem = (label: string, value?: string) => (
    <span key={label} className="border-r-2 px-2 py-1">
      {label.toLocaleLowerCase() !== 'package' && (
        <>
          <span data-testid={`patient-${label}`}>{label === 'DOB' ? `~ ${label}` : label}</span>
          <span className="px-1">
            <span className="relative bottom-0.5 inline-flex size-1 rounded-full bg-neutral-400 dark:bg-neutral-400" />
          </span>
        </>
      )}
      <span data-testid={`patient-${label}-value`}>{value}</span>
    </span>
  );

  const showSku = (sku: string) => {
    if (Number(sku) === 500) {
      return 'R&D';
    }

    if (Number(sku) === 400) {
      return 'WB + consult';
    }

    if (Number(sku) === 350) {
      return 'Enhanced Comprehensive';
    }

    if (Number(sku) === 300) {
      return 'Whole body';
    }

    if (Number(sku) === 200) {
      return 'Head + torso';
    }

    if (Number(sku) === 100) {
      return 'Torso';
    }

    return '';
  };

  const staticInfoMapping = {
    DOB: `${patient?.fake_dob} - ~Age: ${patient?.fake_dob && calculateAge(patient?.dob)}`,
    'Scan Date': formatTimestamp(scanDate),
  };

  const responsiveInfoMapping = {
    Scanner: machineID,
    // eslint-disable-next-line perfectionist/sort-objects
    Identifier: studyId?.slice(0, 8),
    Package: showSku(skuID.slice(-8)),
  };

  const responsiveList = (
    <div className="flex flex-col space-y-2">
      {Object.entries(responsiveInfoMapping).map(([label, value]) => (
        <span key={label} className="px-2 py-1">
          <span data-testid={`header-${label}`}>{label !== 'Package' && label}</span>
          {label !== 'Package' && (
            <span className="px-1">
              <span className="relative bottom-0.5 inline-flex size-1 rounded-full bg-neutral-400 dark:bg-neutral-400" />
            </span>
          )}
          <span data-testid={`header-${label}-value`}>{value}</span>
        </span>
      ))}
    </div>
  );

  return (
    <div
      className={cn(
        'flex h-16 rounded-t-2xl bg-stone-900 dark:bg-stone-900 px-4 text-neutral-400 dark:text-neutral-400',
        className,
      )}
      data-testid="header-container"
    >
      <div className="flex items-center">
        {isPreviewMode ? (
          <IconButton
            aria-label="Chevron left"
            data-testid="chevron-left-button"
            icon={ChevronLeft}
            onClick={navigateBack}
            size="sm"
            variant="text"
          />
        ) : (
          <img alt="Prenuvo Logo" data-testid="header-icon" src={Logo} />
        )}
      </div>
      <div className="flex flex-1 items-center pl-4">
        <span className="flex text-sm leading-none">
          <span className="border-r-2 py-1 pr-2" data-testid="patient-name">
            {patient?.fake_firstname} {patient?.fake_lastname}
          </span>
          {patient?.gender && (
            <GenderSelect
              currentGender={gender}
              onGenderChange={(selectGender: string) => setGender(selectGender)}
            />
          )}
          {Object.entries(staticInfoMapping).map(([label, value]) => infoItem(label, value))}
          <div className="hidden xl:flex">
            {Object.entries(responsiveInfoMapping).map(([label, value]) => infoItem(label, value))}
          </div>
          <div className="flex cursor-pointer pl-2 xl:hidden" data-testid="patient-info-tooltip">
            <Tooltip content={responsiveList} side="bottom">
              <span className="rounded-md bg-stone-800 px-2 py-1">
                +{Object.entries(responsiveInfoMapping).length}
              </span>
            </Tooltip>
          </div>
        </span>
      </div>
      <div className="flex items-center">
        <Button
          aria-label="Medical History"
          className={{ root: 'mr-4' }}
          data-testid="medical-history-button"
          leftIcon={Document}
          onClick={() =>
            window.open(
              `${config.APPS_SERVER}/admin/study/${studyId}/medical-history/radiologist`,
              '_blank',
            )
          }
          size="sm"
          variant="text"
        >
          Pt Hx
        </Button>
        {previousStudyIds.length > 0 && (
          <div className="flex flex-row gap-3" data-testid="previous-study-reports">
            {previousStudyIds.map((id: string, index: number) => (
              <Button
                key={`previous-report-${id}`}
                aria-label="PreviousReport"
                className={{ root: 'bg-stone-700 px-3' }}
                data-testid={`previous-report-btn-${index}`}
                onClick={() =>
                  window.open(
                    `${config.APPS_SERVER}/radiologist/study/${id}/latest-pdf-report`,
                    '_blank',
                  )
                }
                size="sm"
                variant="text"
              >
                P{index + 1}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
