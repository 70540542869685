import { create } from 'zustand';

import { HiStudyState } from './useHiStudy.type';

export const useHiStudy = create<HiStudyState>((set) => ({
  isScanDetailLoading: false,
  medicalHistory: null,
  scanDetails: {
    machineID: '',
    patientID: '',
    scanDate: 0,
    skuID: '',
    technique: null,
  },
  setIsScanDetailLoading: (isScanDetailLoading) => set({ isScanDetailLoading }),
  setMedicalHistory: (medicalHistory) => set({ medicalHistory }),
  setScanDetails: (scanDetails) => set({ scanDetails }),
}));
