import { Button, Dialog, Input, Stack } from '@prenuvo/halo-web';

function KeyImageDialog({
  closeDialog,
  isDialogOpen,
  keyImageLabel,
  saveTitle,
  setKeyImageLabel,
}: {
  closeDialog: () => void;
  isDialogOpen: boolean;
  keyImageLabel: string;
  saveTitle: () => void;
  setKeyImageLabel: (value: string) => void;
}) {
  return (
    <Dialog
      aria-describedby={undefined}
      footer={
        <Stack gap={4} justification="end">
          <Button onClick={closeDialog} size="lg" variant="text">
            Go back
          </Button>
          <Button className={{ root: 'px-8' }} onClick={saveTitle} size="lg" variant="primary">
            Save
          </Button>
        </Stack>
      }
      onOpenChange={closeDialog}
      open={isDialogOpen}
      title={{
        children: 'Key Image Label',
      }}
    >
      <div className="w-full">
        <Input
          data-testid="key-image-label-input"
          label={{ children: 'Key image label' }}
          onChange={(event) => setKeyImageLabel(event.target.value)}
          value={keyImageLabel}
        />
      </div>
    </Dialog>
  );
}

export { KeyImageDialog };
