import { createContext } from 'react';

import { User } from '../../types/user';

export type AuthContextType = {
  isAuthenticated: boolean;
  setUserSignature: (signature: string) => void;
  user: undefined | User;
  userSignature: string;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);
