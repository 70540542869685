import { useEffect, useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Checkmark } from '@prenuvo/halo-icon';
import { Icon } from '@prenuvo/halo-web';
import { AnimatePresence, motion } from 'framer-motion';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useKeyImages } from '@/store/useKeyImages/useKeyImages';
import { LocalKeyImage } from '@/store/useKeyImages/useKeyImages.type';

import { ImageOptions } from './components/ImageOptions/ImageOptions';
import { KeyImageDialog } from './components/KeyImageDialog/KeyImageDialog';
import { deleteImage, updateImageTitle } from './util/DraggableKeyImage.utils';
import { DELETE, EDIT, SELECTED, UNSELECTED } from './DraggableKeyImages.constants';
import { DraggableKeyImages as DraggableKeyImagesCVA } from './DraggableKeyImages.cva';
import { DraggableProps, VariantState } from './DraggableKeyImages.type';

export function DraggableKeyImages({
  id,
  isViewMode = false,
  keyImage,
  parentRef,
  selected,
  shouldEnableHover = true,
  uniqueImages,
}: DraggableProps) {
  const organId = id.split(`${keyImage.originalId}-`)[1];
  const [isOverTheBox, setIsOverTheBox] = useState<boolean>(false);
  const [variant, setVariant] = useState<VariantState>('unselected');
  const [hovered, setHovered] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [keyImageLabel, setKeyImageLabel] = useState<string>(keyImage.title);

  const { attributes, listeners, node, setNodeRef, transform } = useDraggable({
    id,
    data: { id, image: keyImage.image, title: keyImage.title },
  });

  const { localKeyImages, setIsOutsideOfBox, setLocalKeyImages, setManualAddition } =
    useKeyImages();

  useEffect(() => {
    if (selected) {
      setVariant(SELECTED);
    } else {
      setVariant(UNSELECTED);
    }
  }, [selected, isViewMode]);

  useEffect(() => {
    if (transform && parentRef?.current && node.current) {
      const parentRect = parentRef?.current.getBoundingClientRect();
      const nodeRect = node.current.getBoundingClientRect();

      const nodeLeft = nodeRect.left + transform.x;
      const nodeRight = nodeLeft + nodeRect.width;
      const nodeTop = nodeRect.top + transform.y;
      const nodeBottom = nodeTop + nodeRect.height;

      if (
        nodeLeft < parentRect.left ||
        nodeRight > parentRect.right ||
        nodeTop < parentRect.top ||
        nodeBottom > parentRect.bottom
      ) {
        setIsOverTheBox(true);
      } else {
        setIsOverTheBox(false);
      }
    }
  }, [transform, parentRef]);

  useEffect(() => {
    setIsOutsideOfBox(isOverTheBox);

    if (isOverTheBox) {
      setManualAddition(false);
    }
  }, [isOverTheBox]);

  const draggableProps = !isViewMode ? { ...listeners, ...attributes } : {};

  const handleActions = (action: string) => () => {
    let newImages;

    switch (action) {
      case EDIT:
        setIsDialogOpen(true);
        break;
      case DELETE:
        newImages = deleteImage(organId, keyImage, localKeyImages);

        if (newImages) {
          setLocalKeyImages(organId, newImages);
          newImages = null;
        }

        break;
      default:
        break;
    }
  };

  const updateImage = () => {
    const newUpdatedImages = updateImageTitle(organId, keyImageLabel, keyImage, localKeyImages);

    if (newUpdatedImages) {
      setLocalKeyImages(organId, newUpdatedImages);
      setIsDialogOpen(false);
      setHovered(false);
    }
  };

  const renderImage = (keyImageObj: LocalKeyImage) => (
    <div
      className="relative shrink-0"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <KeyImageDialog
        closeDialog={() => setIsDialogOpen(false)}
        isDialogOpen={isDialogOpen}
        keyImageLabel={keyImageLabel}
        saveTitle={() => updateImage()}
        setKeyImageLabel={setKeyImageLabel}
      />
      {isViewMode && shouldEnableHover && (
        <ImageOptions handleActions={handleActions} hovered={hovered} isViewMode={isViewMode} />
      )}
      {isViewMode ? (
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>
                <img
                  className={DraggableKeyImagesCVA({
                    state: variant,
                  })}
                  data-testid="key-images"
                  ref={setNodeRef}
                  src={keyImageObj.image}
                  {...draggableProps}
                  alt={keyImageObj.title}
                />
                <AnimatePresence>
                  {!hovered && (
                    <motion.div
                      animate={{ opacity: 1 }}
                      className="absolute bottom-1 left-2/4 z-10 flex h-7 w-11/12 -translate-x-1/2 items-center justify-start rounded-md bg-neutral-800 dark:bg-neutral-800"
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0 }}
                    >
                      <p className="-z-10 m-0 cursor-default truncate px-2 text-xs font-bold text-neutral-50 dark:text-neutral-50">
                        {keyImageObj.title}
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </TooltipTrigger>
            <TooltipContent className="bg-stone-800 dark:bg-stone-800" side="bottom">
              <p>{keyImageObj.title}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <img
          className={DraggableKeyImagesCVA({
            state: variant,
          })}
          data-testid="key-images"
          ref={setNodeRef}
          src={keyImageObj.image}
          {...draggableProps}
          alt={keyImageObj.title}
        />
      )}
    </div>
  );

  return uniqueImages && uniqueImages.includes(keyImage.image) ? (
    <div className="relative shrink-0 overflow-hidden">
      <span className="absolute right-3 top-3 z-20">
        <Icon aria-label="Checkmark" className="fill-current" size="xs" source={Checkmark} />
      </span>
      <div className="pointer-events-none absolute z-10 size-full rounded-md bg-black opacity-80" />
      {renderImage(keyImage)}
    </div>
  ) : (
    renderImage(keyImage)
  );
}
