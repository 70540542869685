import { useEffect } from 'react';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { HttpMethod } from '@/core/constants';
import { useFormStructure } from '@/store/useFormStructure/useFormStructure';
import { FormStructure } from '@/types/formStructure';
import { transformStructureToNavList } from '@/utils/utils';

import { useApiRequest } from '../useApiRequest/useApiRequest';

export const useFetchFormStructure = () => {
  const { formStructure, setFormStructure } = useFormStructure();
  const { handleApiRequest } = useApiRequest();

  useEffect(() => {
    if (!formStructure) {
      const getFormStructure = async () => {
        await handleApiRequest<FormStructure[]>(
          `${API_ENDPOINTS.organs.getOrgans}?sku=300&status=draft`,
          HttpMethod.GET,
          {},
          (data) => {
            const transformedData = transformStructureToNavList(data[0]);

            setFormStructure(transformedData);
          },
        );
      };

      getFormStructure();
    }
  }, []);
};
