import { create } from 'zustand';

import type { User } from '@/types/user';

import type { AuthStoreState } from './authStore.type';

export const useAuthStore = create<AuthStoreState>((set) => ({
  isAuthenticated: true,
  setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated }),
  setUser: (user: null | User) => set({ user }),
  user: null,
}));
