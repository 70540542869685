import { BaseDDConfig, configure, Env } from '@prenuvo/nimbus-logger';

import { ddLogConfig, ddRumConfig } from './config';

const baseConfig = {
  appId: ddRumConfig.applicationId,
  env: Env[ddLogConfig.env as keyof typeof Env],
  service: ddLogConfig.service,
  token: ddRumConfig.clientToken,
  version: ddLogConfig.version,
};

export const getLogger = (name: string, baseConfigObj: BaseDDConfig) => {
  const logLevel = baseConfigObj.env === 'loc' ? 'debug' : 'info';

  return configure(name, logLevel, baseConfigObj);
};

export const logger = getLogger('FCT-FE', baseConfig as BaseDDConfig);
