/* eslint-disable react/no-array-index-key */

'use client';

import * as React from 'react';
import { cn } from '@prenuvo/halo-web';

import { DotIndicatoVariants } from './DotIndicator.cva';
import { DotIndicatorProps } from './DotIndicator.type';

export function DotIndicator({
  filledDots,
  isViewMode = false,
  label,
  onChange,
  totalDots = 5,
  ...props
}: DotIndicatorProps) {
  const [hoveredIndex, setHoveredIndex] = React.useState<null | number>(null);

  const handleClick = (index: number) => {
    if (onChange) {
      onChange(index + 1);
    }
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>, index: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(index);
    }
  };

  return (
    <div className={DotIndicatoVariants.container} role="status" {...props}>
      <span className={DotIndicatoVariants.label} data-testid="dot-indicator-label">
        {label}
        {isViewMode && ' -'}
      </span>
      {!isViewMode ? (
        <div className="flex">
          {Array.from({ length: totalDots }).map((_, index) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <span
              key={index}
              className={cn(
                DotIndicatoVariants.dot({
                  filled: index < filledDots,
                  hovered: hoveredIndex !== null && index <= hoveredIndex && index >= filledDots,
                }),
              )}
              data-testid={`dot-indicator-dot-${index}`}
              onClick={() => handleClick(index)}
              onKeyDown={(event) => handleKeyDown(event, index)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            />
          ))}
        </div>
      ) : (
        <div>{filledDots}</div>
      )}
    </div>
  );
}
