import { useEffect, useState } from 'react';

import { useAuthStore } from '@/core/store/auth/authStore';

import { API_ENDPOINTS } from '../../../core/api/endpoints';
import { useApiDataFetcher } from '../../../core/api/hooks/useApiDataFetcher';
import { User } from '../../../types/user';

export const useCurrentUserQuery = () => {
  const { data, error, isLoading } = useApiDataFetcher('PII', API_ENDPOINTS.user.getCurrentUser);
  const [currentUser, setCurrentUser] = useState<User>();
  const { setUser } = useAuthStore();

  useEffect(() => {
    if (data) {
      const user = (data as { user: User })?.user;

      setCurrentUser(user);
      setUser(user);
    }
  }, [data]);

  return { error, user: currentUser, userLoading: isLoading };
};
