import { create } from 'zustand';

import { Finding, KeyImage, KeyImagesState, LocalKeyImage } from './useKeyImages.type';

export const useKeyImages = create<KeyImagesState>((set) => ({
  currentDroppableId: null,
  currentIndex: null,
  isKISectionOpen: false,
  isManualAddition: false,
  isOutsideOfBox: false,
  keyImages: [],
  localKeyImages: {},
  openKISection: (open: boolean, finding?: Finding, isManualAddition?: boolean) =>
    set(() => ({
      currentDroppableId: finding?.id,
      currentIndex: finding?.index,
      isKISectionOpen: open,
      isManualAddition,
    })),

  selectedImage: null,

  setCurrentDroppableId: (currentDroppableId: null | string) => set({ currentDroppableId }),

  setIsOutsideOfBox: (isOutsideOfBox: boolean) => set({ isOutsideOfBox }),

  setKeyImages: (keyImages: KeyImage[]) => set({ keyImages }),

  setLocalKeyImages: (id: string, images: LocalKeyImage[]) =>
    set(
      (state) =>
        ({
          localKeyImages: {
            ...state.localKeyImages,
            [id]: images,
          },
        }) as KeyImagesState,
    ),

  setManualAddition: (isManualAddition: boolean) => set({ isManualAddition }),

  setSelectedImage: (selectedImage: KeyImage | null) => set({ selectedImage }),
}));
