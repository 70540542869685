import { LogsInitConfiguration } from '@datadog/browser-logs';
import { RumInitConfiguration } from '@datadog/browser-rum';

export const config = {
  APPS_SERVER,
  BASE_NAME: '/reporting',
  BASE_URL: import.meta.env.BASE_URL,
  DATADOG: {
    APP_ID: DD_APP_ID,
    SUBDOMAIN_TRACKING: true,
    TOKEN: DD_TOKEN,
    USE_SECURE_SESSION: true,
  },
  ENV: import.meta.env.MODE,
  FCT_SERVER,
  HI_SERVER,
  NAME: APP_NAME,
  PII_SERVER,
  VERSION: APP_VERSION,
};

export const ddLogConfig: LogsInitConfiguration = {
  clientToken: config.DATADOG.TOKEN,
  env: config.ENV,
  forwardConsoleLogs: ['info', 'warn', 'error'],
  forwardErrorsToLogs: true,
  service: config.NAME,
  trackSessionAcrossSubdomains: config.DATADOG.SUBDOMAIN_TRACKING,
  useSecureSessionCookie: config.DATADOG.USE_SECURE_SESSION,
  version: config.VERSION,
};

export const ddRumConfig: RumInitConfiguration = {
  applicationId: config.DATADOG.APP_ID,
  clientToken: config.DATADOG.TOKEN,
  defaultPrivacyLevel: 'mask-user-input',
  env: config.ENV,
  service: config.NAME,
  sessionReplaySampleRate: 20,
  sessionSampleRate: 100,
  trackLongTasks: true,
  trackResources: true,
  trackSessionAcrossSubdomains: config.DATADOG.SUBDOMAIN_TRACKING,
  trackUserInteractions: true,
  useSecureSessionCookie: config.DATADOG.USE_SECURE_SESSION,
  version: config.VERSION,
};
