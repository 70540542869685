import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Quill from 'quill';

import { convertTextToBulletPoints } from '@/utils/utils';

import './editor.css';

import { EditorProps } from './Editor.types';

const FORMATS = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula',
];

const EditorComponent = forwardRef(
  ({ isReadOnly = false, onBlur, onChange, placeholder = '', value }: EditorProps, ref) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const quillRef = useRef<null | Quill>(null);
    const [initialValue, setInitialValue] = useState(value);
    const formattedValue = convertTextToBulletPoints(value);

    useImperativeHandle(ref, () => ({
      blur: () => {
        quillRef.current?.blur();
      },
    }));

    useEffect(() => {
      if (editorRef.current) {
        if (!quillRef.current) {
          quillRef.current = new Quill(editorRef.current, {
            bounds: editorRef.current,
            formats: FORMATS,
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ align: ['right', 'center', 'justify'] }],
                [{ list: 'bullet' }],
                [{ color: ['red', 'orange', 'blue'] }],
              ],
            },
            placeholder,
            readOnly: isReadOnly,
            theme: 'bubble',
          });

          quillRef.current.clipboard.dangerouslyPasteHTML(formattedValue);
          setInitialValue(quillRef.current?.root.innerHTML || '');
          quillRef.current.blur();
        }

        if (formattedValue !== quillRef.current.root.innerHTML) {
          quillRef.current.clipboard.dangerouslyPasteHTML(formattedValue);
          quillRef.current.blur();
        }
      }
    }, [value, placeholder]);

    useEffect(() => {
      if (quillRef.current) {
        quillRef.current.on('text-change', () => {
          const changedText = quillRef.current?.root.innerHTML || '';

          if (onChange) {
            onChange(changedText);
          }
        });

        quillRef.current.on('selection-change', (range, oldRange) => {
          if (range === null && oldRange !== null) {
            const currentValue = quillRef.current?.root.innerHTML || '';

            if (onBlur) {
              onBlur(
                initialValue !== currentValue && value !== currentValue ? currentValue : '',
                currentValue,
              );
            }
          }
        });

        return () => {
          quillRef.current?.off('text-change');
          quillRef.current?.off('selection-change');
        };
      }

      return () => {};
    }, [value, onChange, onBlur, initialValue]);

    return <div className="text-base" data-testid="editor" ref={editorRef} />;
  },
);

EditorComponent.displayName = 'Editor';

export const Editor = memo(EditorComponent);
