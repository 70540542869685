import { cn, Icon, IconSource } from '@prenuvo/halo-web';

import { transformSentenceToKebabCase } from '@/utils/utils';

import { Finding } from '../Finding/Finding';
import { organContainerVariants } from './Organ.cva';
import { OrganProps } from './Organ.type';

export function Organ({ isLast, organ }: OrganProps) {
  const formattedOrganTitle = transformSentenceToKebabCase(organ.title);

  return (
    <div
      className={cn(organContainerVariants({ last: isLast }))}
      data-testid={`${formattedOrganTitle}-organ`}
    >
      <div className="flex items-center gap-3">
        <Icon
          aria-label={organ.title}
          className="size-[54px] fill-neutral-400"
          size="lg"
          source={organ.icon as IconSource}
        />
        <div>
          <p className="text-2xl capitalize" data-testid={`${formattedOrganTitle}-organ-title`}>
            {organ.title}
          </p>
          <p className={cn('text-red-400 text-sm')}>
            {organ.findings.length > 1
              ? `${organ.findings.length} Findings`
              : `${organ.findings.length} Finding`}
            , (N) Requires urgent attention
          </p>
        </div>
      </div>
      <div>
        <div className="my-12">
          {organ.findings
            .filter((finding) => finding.observation === '')
            .map((finding, findingIndex) => (
              <Finding
                key={`${finding.organ}-${finding.uuid}`}
                finding={finding}
                isLast={
                  findingIndex ===
                  organ.findings.filter((findingData) => findingData.observation === '').length - 1
                }
                isOnDemandCondition
              />
            ))}
        </div>
        {organ.findings
          .filter((finding) => finding.observation)
          .map((finding, findingIndex) => (
            <Finding
              key={`${finding.organ}-${finding.uuid}`}
              finding={finding}
              isLast={findingIndex === organ.findings.length - 1}
            />
          ))}
      </div>
    </div>
  );
}
