export const BASE_API_URL = '/api/v1';

export const API_ENDPOINTS = {
  conditionTemplate: {
    getConditionTemplate: `${BASE_API_URL}/condition-template/`,
  },
  finding: {
    getStructuredFinding: `${BASE_API_URL}/text-to-structured-observation/`,
    renderedSummary: `${BASE_API_URL}/rendered-summary/`,
    reportFinding: `${BASE_API_URL}/report-finding/`,
  },
  hi: {
    radStudy: '/radiologist/study/',
    study: `${BASE_API_URL}/study`,
  },
  organs: {
    getOrgans: `${BASE_API_URL}/form-structure/`,
  },
  report: {
    getReport: `${BASE_API_URL}/report/study`,
    saveReport: `${BASE_API_URL}/report`,
  },
  study: {
    getStudy: `${BASE_API_URL}/study/`,
  },
  user: {
    getCurrentUser: `${BASE_API_URL}/userV2/current`,
    getPatient: `${BASE_API_URL}/userV2/`,
  },
};
