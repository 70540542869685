import { useState } from 'react';

import { makeApiRequest } from '@/core/api/makeApiRequest/makeApiRequest';

export const useApiRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const handleApiRequest = async <T>(
    endpoint: string,
    method: 'GET' | 'PATCH' | 'POST',
    body: null | Record<string, unknown>,
    onSuccess: (data: T) => void,
    onError?: (error: string) => void,
  ) => {
    try {
      setIsLoading(true);
      const { data } = await makeApiRequest('FCT', endpoint, method, body);

      onSuccess(data as T);
    } catch (err: Error | unknown) {
      const errorMessage = (err as Error).message || 'An error occurred';

      setError(errorMessage);

      if (onError) {
        onError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    handleApiRequest,
    isLoading,
  };
};
