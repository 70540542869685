import useSWR from 'swr';

import { makeApiRequest } from '../makeApiRequest/makeApiRequest';
import { ApiId, HttpMethod } from '../types';

export const useApiDataFetcher = <T>(
  api: ApiId,
  path: string,
  method: HttpMethod = 'GET',
  body?: unknown,
) => {
  const useFetcherData = async () => {
    const response = await makeApiRequest<T>(api, path, method, body, {});

    return response.data;
  };

  const { data, error, isLoading, isValidating, mutate } = useSWR<T>(
    [api, path, method, body],
    useFetcherData,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return { data, error, isLoading, isValidating, mutate };
};
