import { useRef, useState } from 'react';
import { cn, Icon } from '@prenuvo/halo-web';
import { motion, Variants } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

import { useOutsideClick } from '@/hooks/useOutsideClick/useOutsideClick';

const VARIANTS: Variants = {
  closed: { opacity: 0, visibility: 'hidden' },
  open: { opacity: 1, visibility: 'visible' },
};

interface GenderSelectProps {
  currentGender: string;
  onGenderChange: (gender: string) => void;
}

export function GenderSelect({ currentGender, onGenderChange }: GenderSelectProps): JSX.Element {
  const genderRef = useRef<HTMLSpanElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const genderOptions: { [key: string]: string } = {
    female: 'Female',
    male: 'Male',
  };

  const selectedGender = genderOptions[currentGender];

  const selectOptions = Object.entries(genderOptions).map(([key, value]) => (
    <li
      key={key}
      aria-selected={selectedGender === value}
      className={cn(
        'p-3 text-neutral-50 dark:text-neutral-50 text-sm cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 ring-offset-background border-b last-of-type:border-0 whitespace-nowrap hover:bg-stone-700 dark:hover:bg-stone-700 font-normal',
        {
          'bg-stone-700 dark:bg-stone-700': selectedGender === value,
        },
      )}
      data-testid={`gender-option-${key}`}
      onClick={() => {
        onGenderChange(key);
        setIsSelectOpen(false);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onGenderChange(key);
          setIsSelectOpen(false);
        }
      }}
      role="option"
      tabIndex={0}
    >
      {value}
    </li>
  ));

  useOutsideClick([genderRef], () => {
    setIsSelectOpen(false);
  });

  const onIconClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.right + window.scrollX - rect.width;
    const y = rect.top + window.scrollY + rect.height;

    setTooltipPosition({ x, y });

    setIsSelectOpen(!isSelectOpen);
  };

  return (
    <span className="flex border-r-2 px-2 py-1">
      <span className="font-bold" data-testid="patient-gender">
        {selectedGender}
      </span>
      <Icon
        className="cursor-pointer"
        data-testid="gender-chevron-down"
        onClick={onIconClick}
        size="xs"
        source={ChevronDown}
      />
      <motion.div
        animate={isSelectOpen ? 'open' : 'closed'}
        className={cn('z-[100]', {
          'fixed top-0 left-0 w-screen h-screen': isSelectOpen,
          'pointer-events-none': !isSelectOpen,
        })}
        data-testid="gender-text-tooltip"
        variants={VARIANTS}
      >
        <div
          className={cn(
            'custom-scrollbar fixed top-2 z-[101] overflow-y-auto rounded bg-stone-900 dark:bg-stone-900 shadow-lg shadow-stone-950 dark:shadow-stone-950',
            {
              'pointer-events-auto': isSelectOpen,
            },
          )}
          data-testid="interactive-text-overlay"
          ref={overlayRef}
          style={{
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          <span className="flex flex-col items-end space-y-5" ref={genderRef}>
            <ul className="rounded-2xl" data-testid="gender-select">
              {selectOptions}
            </ul>
          </span>
        </div>
      </motion.div>
    </span>
  );
}
