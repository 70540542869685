import { KeyImage, LocalKeyImage } from '@/store/useKeyImages/useKeyImages.type';

export const deleteImage = (
  organId: string,
  selectedKeyImage: KeyImage,
  localKeyImages: Record<string, LocalKeyImage[]>,
) => {
  const images = localKeyImages[organId].filter(
    (image: LocalKeyImage) => image.image !== selectedKeyImage.image,
  );

  return images;
};

export const updateImageTitle = (
  organId: string,
  newLabel: string,
  selectedKeyImage: LocalKeyImage,
  localKeyImages: Record<string, LocalKeyImage[]>,
) => {
  const images = localKeyImages[organId].map((image: LocalKeyImage) => {
    if (image.image === selectedKeyImage.image) {
      return { ...image, title: newLabel };
    }

    return image;
  });

  return images;
};
