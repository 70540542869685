import { create } from 'zustand';

import { ConditionsData, ConditionsState } from './useConditionsStore.type';

export const useConditionsStore = create<ConditionsState>((set, get) => ({
  conditions: {},
  getConditions: (organ: string) => get().conditions[organ] || [],
  setConditons: (organ: string, conditions: ConditionsData[]) => {
    set((state) => ({
      conditions: {
        ...state.conditions,
        [organ]: [...(state.conditions[organ] ?? []), ...conditions],
      },
    }));
  },
}));
